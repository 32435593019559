import React from 'react';
import './DailyBonus.css';

const bonusData = [
    { day: 1, coins: 1500, isActive: false },
    { day: 2, coins: 2500, isActive: false },
    { day: 3, coins: 4500, isActive: false },
    { day: 4, coins: 7500, isActive: false },
    { day: 5, coins: 10500, isActive: true },
    { day: 6, coins: 30500, isActive: true },
    { day: 7, coins: 45500, isActive: true },
];

const DailyBonus = () => (
    <div className="daily-bonus">
        <h3>Заходи 30 дней и получай бонусы</h3>
        <div className="bonus-icons">
            {bonusData.map((bonus, index) => (
                <div
                    key={index}
                    className={`bonus-item ${bonus.isActive ? 'active' : ''}`}
                >
                    <div className="circle-icon">
                        <img
                            src={`/images/tasks/${bonus.isActive ? 'check-circle.png' : 'checked-circle.png'}`}
                            alt={bonus.isActive ? 'Active Bonus' : 'Inactive Bonus'}
                        />
                    </div>
                    <div className="coins">
                        <img src="/images/tasks/wcoin.png" alt="WCoin Icon" />
                        <span>{bonus.coins}</span>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default DailyBonus;
