import React from 'react';
import './StatsSection.css';
import { useSelector } from "react-redux";

const StatsSection = () => {
    const user = useSelector((state) => state.user.value);
    const coinsDaily = user.coins_daily;
    const coinsMonthly = user.coins_monthly;
    const chanceDaily = user.chance_daily;
    const chanceMonthly = user.chance_monthly;
    const chanceColor = (chance) => {
        if (chance <= 30) {
            return '#c93a3a';
        } else if (chance > 30 && chance <= 70) {
            return '#FFE764';
        } else {
            return '#4ABA24';
        }
    };

    return (
        <div className="stats-section">
            <div className="stat-item">
                <div className="stat-section">
                    <p className="stat-label">Earn today</p>
                    <div className="stat-content">
                        <img src="/images/wcoin-mini.png" alt="WCoin Icon" className="stat-icon"/>
                        <span className="stat-value">{ coinsDaily }</span>
                    </div>
                </div>
                <div className="divider"></div>
            </div>

            <div className="stat-item">
                <div className="stat-section">
                    <p className="stat-label">Earn month</p>
                    <div className="stat-content">
                        <img src="/images/wcoin-mini.png" alt="WCoin Icon" className="stat-icon"/>
                        <span className="stat-value">{ coinsMonthly }</span>
                    </div>
                </div>
                <div className="divider"></div>
            </div>

            <div className="stat-item">
                <div className="stat-section">
                    <p className="stat-label">Win chance day</p>
                    <div className="stat-content">
                        <span className="stat-value" style={{ color: chanceColor(chanceDaily) }}>{ chanceDaily }%</span>
                        <div className="info-icon">
                            <img src="/images/info2.png" alt="Info Icon"/>
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
            </div>

            <div className="stat-item">
                <div className="stat-section">
                    <p className="stat-label">Win chance month</p>
                    <div className="stat-content">
                        <span className="stat-value" style={{ color: chanceColor(chanceMonthly) }}>{ chanceMonthly }%</span>
                        <div className="info-icon">
                            <img src="/images/info2.png" alt="Info Icon"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatsSection;
