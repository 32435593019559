import React from 'react';
import "./Tasks.css"
import LayoutWelcome from "../app/components/layout/LayoutWelcome";
import Header from "./components/header/Header";
import DailyBonusBar from "./components/dailyBonus/DailyBonus";
import TaskLinker from "./components/taskLinker/TaskLinker";
import TaskList from "./components/taskList/TaskList";
import NavigationBar from "../app/components/navigationBar/NavigationBar";

const Tasks = () => {
    return (
        <LayoutWelcome>
            <div className="tasks">
                <Header />
                <DailyBonusBar />
                <TaskLinker />
                <TaskList />
            </div>
            <div className="tasks-footer">
                <NavigationBar />
            </div>
        </LayoutWelcome>
    );
};

export default Tasks;
