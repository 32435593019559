import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <div className="header">
            <img src="/images/tasks/book.png" alt="Book Icon" className="header-icon"/>
            <h2>Выполняй задания</h2>
            <p>И зарабатывай WCoins, чтобы обменять их на фрибеты от Winline</p>
            <hr/>
        </div>
    );
};

export default Header;
