import React, { useState, useEffect } from 'react';
import './Welcome.css';

const Welcome = ({ user, onStartGame }) => {
    const [showDescription, setShowDescription] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [visiblePlaceholders, setVisiblePlaceholders] = useState([false, false, false, false]);

    useEffect(() => {
        const descriptionTimeout = setTimeout(() => setShowDescription(true), 1000);
        const buttonTimeout = setTimeout(() => setShowButton(true), 2000);

        return () => {
            clearTimeout(descriptionTimeout);
            clearTimeout(buttonTimeout);
        };
    }, []);

    useEffect(() => {
        if (showButton) {
            visiblePlaceholders.forEach((_, index) => {
                setTimeout(() => {
                    setVisiblePlaceholders((prev) => {
                        const newPlaceholders = [...prev];
                        newPlaceholders[index] = true;
                        return newPlaceholders;
                    });
                }, 150 * (index + 1));
            });
        }
    }, [showButton, visiblePlaceholders]);

    const handleStartGame = () => {
        if (onStartGame) {
            onStartGame();
        }
    };

    return (
        <div className="app-container">
            <div className="welcome-section fade-in">
                <img src="/images/winline.png" alt="Top Logo" className="winline-logo" />
                <h1 className="welcome-title">Привет!</h1>
                <div className="user-info">
                    <img src={user.avatar} alt="User Avatar" className="user-avatar"/>
                    <p className="user-name">{user.name}</p>
                </div>
            </div>

            {showDescription && (
                <div className="description-section fade-in">
                    <div className="vertical-squares">
                        <div className="vertical-square" />
                        <div className="vertical-square" />
                    </div>
                    <p className='welcome-question'>Что тут будет?</p>
                    <p className="welcome-description">
                        Каждый день и месяц мы проводим розыгрыш призов. Шанс победы зависит
                        от заработанных
                        <img src="/images/wcoin.png" alt="WCoin"/>
                        WCoins
                    </p>
                </div>
            )}

            {showButton && (
                <div className="action-section fade-in">
                    <div className="vertical-squares">
                        <div className="vertical-square"/>
                        <div className="vertical-square"/>
                    </div>
                    <p className='welcome-question'>Как заработать WCoins?</p>
                    <div className="placeholder">
                        {['/images/up.png', '/images/calendar-dates.png', '/images/book-open2.png', '/images/users2.png'].map((src, index) => (
                            <div
                                key={index}
                                className={`placeholder-item ${visiblePlaceholders[index] ? 'visible' : ''}`}
                            >
                                <img src={src} alt="Icon"/>
                                <span>
                                    {index === 0 && 'Кликайте на персонажа'}
                                    {index === 1 && 'Заходите в игру каждый день'}
                                    {index === 2 && 'Выполняйте задания'}
                                    {index === 3 && 'Приглашайте друзей'}
                                </span>
                            </div>
                        ))}
                    </div>
                    <button className="start-button" onClick={handleStartGame}>
                        Начать тапать
                    </button>
                </div>
            )}
        </div>
    );
};

export default Welcome;
