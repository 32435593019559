import { api } from "../request";
import { initData } from "../utils/tg";

export const getUserState = () => api.get('/state', {
    headers: { 'X-Init-Data': initData() }
})

export const setUserClicks = (data) => api.post('/clicks', JSON.stringify(data), {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Init-Data': initData(),
    }
});