import React from 'react';
import "./Questions.css";

export const Questions = () => {
    return (
        <div className="faq-questions">
            <hr/>
            <div className="faq-questions-block">
                <div className="faq-question">
                    <p className="faq-question-title">Вопрос 1</p>
                    <p className="faq-question-text">
                        Чат-боты становятся неотъемлемой частью современного бизнеса, помогая автоматизировать общение с
                        клиентами
                    </p>
                </div>
                <div className="faq-question">
                    <p className="faq-question-title">Вопрос 2</p>
                    <p className="faq-question-text">
                        Чат-боты становятся неотъемлемой частью современного бизнеса, помогая автоматизировать общение с
                        клиентами
                    </p>
                </div>
                <div className="faq-question">
                    <p className="faq-question-title">Вопрос 3</p>
                    <p className="faq-question-text">
                        Чат-боты становятся неотъемлемой частью современного бизнеса, помогая автоматизировать общение с
                        клиентами
                    </p>
                </div>
                <div className="faq-question">
                    <p className="faq-question-title">Вопрос 4</p>
                    <p className="faq-question-text">
                        Чат-боты становятся неотъемлемой частью современного бизнеса, помогая автоматизировать общение с
                        клиентами
                    </p>
                </div>
            </div>
        </div>
    );
};
