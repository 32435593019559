import React, { useState, useEffect, useRef } from 'react';
import './MainCounter.css';
import Energy from '../../../tap/components/energy/Energy';
import BoostButton from '../../../tap/components/boostButton/BoostButton';
import { useDispatch, useSelector } from "react-redux";
import { setUserClicks } from "../../../../api/user";
import { enableHapticFeedBack, maxEnergy } from "../../../../utils/tg";
import { incrementCoinsDaily, incrementCoinsMonthly } from "../../../../store/userSlice";

const MainCounter = () => {
    const user = useSelector((state) => state.user.value);
    const [coins, setCoins] = useState(user.coins);
    const [coinAnimations, setCoinAnimations] = useState([]);
    const [energy, setEnergy] = useState(user.energy);
    const [newClicks, setNewClicks] = useState(0);
    const lastEventRef = useRef(null);
    const energyRef = useRef(energy);
    const [isClicked, setIsClicked] = useState(false);
    const timerRef = useRef(null);
    const energyIntervalRef = useRef(null);
    const dispatch = useDispatch();

    const handleIncrementCoinsDaily = (value) => {
        dispatch(incrementCoinsDaily(value));
    };

    const handleIncrementCoinsMonthly = (value) => {
        dispatch(incrementCoinsMonthly(value));
    };

    useEffect(() => {
        const handleSetClick = async (clickCount) => {
            if (clickCount > 0) {
                try {
                    await setUserClicks({ clicks: clickCount });
                } catch (error) {
                    console.error('Error setting user clicks:', error);
                }
            }
        };

        if (newClicks > 0) {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
                handleSetClick(newClicks);
                setNewClicks(0);
            }, 3000);
        }

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [newClicks]);

    useEffect(() => {
        energyIntervalRef.current = setInterval(() => {
            setEnergy((prevEnergy) => {
                if (prevEnergy < maxEnergy) {
                    const newEnergy = prevEnergy + 1;
                    energyRef.current = newEnergy;
                    return newEnergy;
                }
                return prevEnergy;
            });
        }, 3000);

        return () => {
            clearInterval(energyIntervalRef.current);
        };
    }, []);

    const handleInteraction = (count, positions) => {
        enableHapticFeedBack();

        if (energyRef.current < count) {
            console.log('Недостаточно энергии для взаимодействия');
            return;
        }

        handleIncrementCoinsDaily(count);
        handleIncrementCoinsMonthly(count);

        setEnergy((prevEnergy) => {
            const newEnergy = prevEnergy - count;
            energyRef.current = newEnergy;
            return newEnergy;
        });

        setCoins((prevCoins) => prevCoins + count);
        setNewClicks((prevNewClicks) => prevNewClicks + count);

        const newAnimations = positions.map((position) => ({
            id: Date.now() + Math.random(),
            x: position.x,
            y: position.y,
            value: `+1`
        }));
        setCoinAnimations((prevAnimations) => [...prevAnimations, ...newAnimations]);

        setTimeout(() => {
            setCoinAnimations((prevAnimations) =>
                prevAnimations.filter((anim) => !newAnimations.includes(anim))
            );
        }, 1000);
    };

    const handleClick = (event) => {
        if (lastEventRef.current === 'touch') return;
        lastEventRef.current = 'click';

        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 100);

        if (energyRef.current > 0) {
            handleInteraction(1, [{ x: event.clientX, y: event.clientY }]);
        } else {
            console.log('Недостаточно энергии для клика');
        }
    };

    const handleTouchStart = (event) => {
        const touchCount = new Set(Array.from(event.changedTouches, touch => touch.identifier)).size;

        if (lastEventRef.current === 'click') return;
        lastEventRef.current = 'touch';

        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 100);

        const touchPositions = Array.from(event.changedTouches).map((touch) => ({
            x: touch.clientX,
            y: touch.clientY,
        }));

        if (energyRef.current >= touchCount) {
            handleInteraction(touchCount, touchPositions);
        } else {
            console.log('Недостаточно энергии для тача');
        }
    };

    return (
        <div className="main-counter"
             onClick={handleClick}
             onTouchStart={handleTouchStart}
        >
            <div className="counter-value">
                <img src="/images/wcoin-icon.png" alt="coin" className="coin-icon" />
                {coins.toLocaleString('en-US')}
            </div>

            <img
                src="/images/winner.png"
                width={410}
                height={926}
                alt="character"
                className={`character ${isClicked ? 'clicked' : ''} ${energy === 0 ? 'disabled' : ''}`}
            />

            {coinAnimations.map((anim) => (
                <div
                    key={anim.id}
                    className="coin-animation"
                    style={{ left: `${anim.x}px`, top: `${anim.y}px` }}
                >
                    {anim.value}
                </div>
            ))}

            <div className="tap-boost">
                <Energy currentEnergy={energy} maxEnergy={maxEnergy} />
                <BoostButton />
            </div>
        </div>
    );
};

export default MainCounter;
