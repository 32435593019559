import React from 'react';
import './TaskLinker.css';

const TaskLinker = () => {
    return (
        <div className="task-linker">
            <div className="task-linker-header">
                <img src="/images/tasks/winline.png" alt="Winline Logo" />
            </div>
            <div className="task-linker-content">
                <p>Привяжите аккаунт Winline, чтобы начать выполнять задания</p>
                <a href="https://api.winplay.tech/link/winline-redirect">
                    <button className="link-account-button">Привязать аккаунт</button>
                </a>
            </div>
        </div>
    );
};

export default TaskLinker;
