import React, { useEffect } from 'react';
import "./Faq.css"
import LayoutWelcome from "../app/components/layout/LayoutWelcome";
import { Information } from "./components/information/Information";
import { Questions } from "./components/questions/Questions";
import { initBackButton } from "../../utils/tg";

const Faq = () => {
    useEffect(() => {
        initBackButton();
    }, []);

    return (
        <LayoutWelcome>
           <div className="faq-container">
               <Information />
               <Questions />
           </div>
        </LayoutWelcome>
    );
};

export default Faq;
