import React from 'react';
import './TaskList.css';

const tasks = [
    { text: 'Сделай депозит 500 рублей на CS2', coins: 80000, isLocked: true },
    { text: 'Сделай депозит 2500 рублей на Dota2', coins: 200000, isLocked: true },
    { text: 'Сделай депозит 2500 рублей на Dota2', coins: 200000, isLocked: false },
    { text: 'Сделай депозит 2500 рублей на Dota2', coins: 200000, isLocked: true },
];

const TaskList = () => (
    <div className="task-list">
        {tasks.map((task, index) => (
            <div key={index} className="task-item">
                <img src="/images/tasks/chip.png" alt="Task Icon" className="img-chip"/>
                <div className="task-content">
                    <span className="task-text">{task.text}</span>
                    <div className="task-info">
                        <div className="coin-section">
                            <img src="/images/tasks/wcoin.png" alt="Coin Icon" className="coin-icon" />
                            <span className="coin-amount">{task.coins.toLocaleString()}</span>
                        </div>
                        <div className="progress-bar">
                            {Array(8).fill(0).map((_, i) => (
                                <div key={i} className={`progress-dot ${i < 4 ? 'active' : ''}`} />
                            ))}
                        </div>
                    </div>
                </div>
                <img src="/images/tasks/lock.png" alt="Lock Icon" />
            </div>
        ))}
    </div>
);

export default TaskList;
