import React from 'react';
import './BoostButton.css';

const BoostButton = () => {
    const handleClick = (event) => {
        event.stopPropagation();
    };

    const onTouch = (event) => {
        event.stopPropagation();
    };

    return (
        <div onClick={handleClick} onTouchStart={onTouch} className="boost-button-div">
            <button className="boost-button">
                <img src="/images/rocket2.png" alt="Boost Icon"/>
                Boost
            </button>
        </div>
    );
};

export default BoostButton;
