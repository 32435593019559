import React from 'react';
import './NavigationBar.css';
import {Link} from "react-router-dom";

const NavigationBar = () => {
    return (
        <div className="navigation-bar">
            <Link to="/">
                <div className="nav-item">
                    <img src="/images/home.png" alt="Главная"/>
                    <span>Главная</span>
                </div>
            </Link>
            <div className="nav-item">
                <img src="/images/gift.png" alt="Конкурсы"/>
                <span>Конкурсы</span>
            </div>
            <Link to="/tasks">
                <div className="nav-item">
                    <img src="/images/book-open.png" alt="Задания"/>
                    <span>Задания</span>
                </div>
            </Link>
            <div className="nav-item">
                <img src="/images/users.png" alt="Друзья"/>
                <span>Друзья</span>
            </div>
        </div>
    );
};

export default NavigationBar;