import React from 'react';
import './Energy.css';

const Energy = ({ currentEnergy, maxEnergy }) => {
    return (
        <div className="tap-energy">
            <img src="/images/energy-icon2.png" alt="Energy Icon" className="energy-icon" />
            <div className="energy-text">
                <span className="current-energy">{currentEnergy}</span>
                <span className="max-energy">/{maxEnergy}</span>
            </div>
        </div>
    );
};

export default Energy;
