import React from 'react';
import './LayoutMain.css'

const Layout = ({ children }) => {
    return (
        <div className="main-layout-container">
            <main className="main-content">
                {children}
            </main>
        </div>
    );
};

export default Layout;
