export const initBackButton = () => {
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.BackButton.onClick(() => {
        window.history.back();
    });
};

export const hideBackButton = () => {
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.BackButton.hide();
};

export const TG_BOT_ENDPOINT = `${process.env.REACT_APP_TG_BOT_ENDPOINT}${process.env.REACT_APP_TG_BOT_TOKEN}`;
export const TG_BOT_IMAGE_ENDPOINT = `${process.env.REACT_APP_TG_PROFILE_IMAGE_ENDPOINT}${process.env.REACT_APP_TG_BOT_TOKEN}`;

export const maxEnergy = 3600;

export const initData = () => {
    const telegram = window.Telegram.WebApp;
    const { initData } = telegram;
    return initData;
};

export const enableHapticFeedBack = () => {
    if (window.Telegram) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');
    }
};

export const expandApp = () => {
    if (window.Telegram.WebApp) {
        window.Telegram.WebApp.expand();
    }
};