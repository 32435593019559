import { createBrowserRouter } from "react-router-dom";
import ErrorScreen from "../modules/app/components/errorScreen/ErrorScreen";
import Home from "../modules/app/components/home/Home";
import Faq from "../modules/faq/Faq";
import Tasks from "../modules/task/Tasks";
import WinlineCallback from "../modules/winline/WinlineCallback";

export const router = createBrowserRouter([
    {
        path: "/tasks",
        element: <Tasks />,
        errorElement: <ErrorScreen />,
    },
    {
        path: "/faq",
        element: <Faq />,
        errorElement: <ErrorScreen />,
    },
    {
        path: "/winline-callback",
        element: <WinlineCallback />,
        errorElement: <ErrorScreen />,
    },
    {
        path: "*",
        element: <Home />,
        errorElement: <ErrorScreen />,
    },
]);
