import React from 'react';
import "./Information.css";

export const Information = () => {
    return (
        <div className="faq-information">
            <img src="/images/faq.png" alt="faq-icon" className="faq-icon"/>
            <p className="faq-title">
                FAQ
            </p>
            <p className="faq-text">
                Если у вас остались вопросы —
                напишите нам
            </p>
            <div className="faq-window">
                <img src="/images/email.png" alt="email-icon" className="email-icon"/>
                <span className="faq-email">example@mail.com</span>
                <div className="email-btn">
                    <img src="/images/email-btn.png" alt="email-icon" className="email-btn-icon"/>
                </div>
            </div>
        </div>
    );
};
