import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {linkWinline} from "../../api/winline";

const WinlineCallback = () => {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');

        if (code && state) {
            const data = {code, state};
            linkWinline(data)
                .then(response => {
                    console.log('Winline linked successfully:', response);
                })
                .catch(error => {
                    console.error('Error linking Winline:', error);
                });
        }
    }, [location]);

    return null;
};

export default WinlineCallback;
