import {api} from "../request";
import {initData} from "../utils/tg";

export const linkWinline = (data) => {
    return api.post('/link-winline', JSON.stringify(data), {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Init-Data': initData(),
        }
    });
};
