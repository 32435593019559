import React, { useEffect } from 'react';
import { hideBackButton } from "../../../../utils/tg";
import './Layout.css';

const LayoutWelcome = ({ children }) => {
    useEffect(() => {
        hideBackButton();
    }, []);

    return (
        <div className="welcome-layout-container">
            <main className="welcome-content">
                {children}
            </main>
        </div>
    );
};

export default LayoutWelcome;
