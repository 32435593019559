import React, { useState, useEffect, useCallback } from 'react';
import Layout from "../layout/Layout";
import LayoutWelcome from "../layout/LayoutWelcome";
import LayoutMain from '../layout/LayoutMain';
import Loader from '../loader/Loader';
import Welcome from "../../../user/components/welcome/Welcome";
import Navbar from "../navbar/Navbar";
import StatsSection from "../../../tap/components/statsSection/StatsSection";
import MainCounter from "../../../tap/components/mainCounter/MainCounter";
import NavigationBar from "../navigationBar/NavigationBar";
import Auth from "../../../user/components/auth/Auth";
import { useDispatch } from 'react-redux';
import './Home.css';
import { setValue } from "../../../../store/userSlice";
import { getUserPhotos, getFile } from "../../../../api/tg";
import { TG_BOT_IMAGE_ENDPOINT } from "../../../../utils/tg";
import { getUserState } from "../../../../api/user";
import { expandApp } from "../../../../utils/tg";

const Home = () => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showLoader, setShowLoader] = useState(true);
    const [gameStarted, setGameStarted] = useState(false);
    const dispatch = useDispatch();

    const handleSetUser = useCallback((newValue) => {
        dispatch(setValue(newValue));
    }, [dispatch]);

    const fetchUserAvatar = useCallback(async (fileId) => {
        try {
            const fileResponse = await getFile({ file_id: fileId });
            const filePath = fileResponse.data.result.file_path;
            return `${TG_BOT_IMAGE_ENDPOINT}/${filePath}`;
        } catch (error) {
            console.error("Error fetching user avatar:", error);
            return '/images/default-avatar.png';
        }
    }, []);

    const fetchUserPhotos = useCallback(async (id) => {
        try {
            const photosResponse = await getUserPhotos({ user_id: id });
            const photos = photosResponse.data.result?.photos;

            if (!photos || photos.length === 0) {
                return '/images/default-avatar.png';
            }

            const fileId = photos[0]?.[0]?.file_id;
            return await fetchUserAvatar(fileId);
        } catch (error) {
            console.error("Error fetching user photos:", error);
            return '/images/default-avatar.png';
        }
    }, [fetchUserAvatar]);

    const initUser = useCallback(async () => {
        const telegram = window.Telegram.WebApp;
        const { initDataUnsafe } = telegram;

        if (initDataUnsafe?.user) {
            const { first_name, last_name, id } = initDataUnsafe.user;
            const avatar = await fetchUserPhotos(id);

            setUser({
                name: `${first_name} ${last_name}`,
                avatar,
                id
            });

            setIsLoggedIn(true);

            try {
                const response = await getUserState();
                const { state } = response.data;
                handleSetUser(state[0]);
            } catch (error) {
                console.error("Error fetching user state:", error);
            }
        }
    }, [fetchUserPhotos, handleSetUser]);

    useEffect(() => {
        expandApp();
        const delay = new Promise((resolve) => setTimeout(resolve, 2000));
        const loadUserData = async () => {
            await Promise.all([initUser(), delay]);
            setLoading(false);
            setShowLoader(false);
        };
        loadUserData();
    }, [initUser]);

    const handleStartGame = () => {
        setGameStarted(true);
    };

    if (showLoader || loading) {
        return (
            <Layout>
                <Loader />
            </Layout>
        );
    }

    if (!isLoggedIn) {
        return (
            <Auth onAuth={(user, userExists) => {
                setUser(user);
                setIsLoggedIn(true);
                if (userExists) {
                    handleStartGame();
                }
            }} />
        );
    }

    if (!gameStarted) {
        return (
            <LayoutWelcome>
                <Welcome user={user} onStartGame={handleStartGame} />
            </LayoutWelcome>
        );
    }

    return (
        <LayoutMain>
            <Navbar user={user} />
            <StatsSection />
            <MainCounter />
            <NavigationBar />
        </LayoutMain>
    );
};

export default Home;