import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: {
        coins: 0,
        coins_daily: 0,
        coins_monthly: 0,
        energy: 3600,
        chance_daily: 0,
        chance_monthly: 0,
        winline_linked_at: null,
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setValue: (state, action) => {
            state.value = action.payload;
        },
        incrementCoinsDaily: (state, action) => {
            state.value.coins_daily += action.payload;
        },
        incrementCoinsMonthly: (state, action) => {
            state.value.coins_monthly += action.payload;
        },
    },
});

export const { setValue, incrementCoinsDaily, incrementCoinsMonthly } = userSlice.actions;
export default userSlice.reducer;
