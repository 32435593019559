import { useEffect } from 'react';
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const authenticateUser = async (initData) => {

    try {
        const response = await api.post('/api/auth/telegram', {
            initData,
        });

        const token = response.data.token;
        localStorage.setItem('authToken', token);

        return response.data.exists;
    } catch (error) {
        console.log(error)
    }
};

const getProtectedData = async () => {
    try {
        const response = await api.get('/protected-route');
        return response.data;
    } catch (error) {
        console.log(error)
    }
};

const Auth = ({ onAuth }) => {
    useEffect(() => {
        if (window.Telegram) {
            window.Telegram.WebApp.ready();
            const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

            if (initDataUnsafe && initDataUnsafe.user) {
                const userData = {
                    id: initDataUnsafe.user.id,
                    first_name: initDataUnsafe.user.first_name,
                    last_name: initDataUnsafe.user.last_name,
                    photo_url: initDataUnsafe.user.photo_url || '/images/default-avatar.png',
                    username: initDataUnsafe.user.username,
                    auth_date: initDataUnsafe.user.auth_date,
                };

                const params = new URLSearchParams(userData).toString();

                const authenticateAndSetUser = async () => {
                    const userExists = await authenticateUser(params);

                    onAuth(userData, userExists);

                    if (userExists) {
                        getProtectedData();
                    }
                };

                authenticateAndSetUser();
            } else {
                console.log('error')
            }
        }
    }, [onAuth]);

    return null;
};

export default Auth;
