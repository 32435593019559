import React from 'react';
import './Navbar.css';
import { Link } from "react-router-dom";

const Navbar = ({ user }) => {
    return (
        <div className="navbar">
            <div className="profile-container">
                <img src={user.avatar} alt={user.name} className="user-avatar"/>
                <div className="profile-details">
                    <div className="profile-information">
                        <p className="user-name">{user.name}</p>
                    </div>
                    <div className="winline-status">
                        <img src="/images/winline-mini.png" alt="Winline"/>
                        <span>Аккаунт не привязан.
                            <a
                                href="https://api.winplay.tech/link/winline-redirect"
                                className="winline-auth"
                            >
                                Привязать
                            </a>
                        </span>
                    </div>
                </div>
                <Link to="/faq" className="info-btn">
                    <img src="/images/question.png" alt="faq"/>
                </Link>
            </div>
        </div>
    );
};

export default Navbar;
